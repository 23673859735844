//
// Width and height
//

// Auto
.h-auto {
    height: auto !important;
}

.w-auto {
    width: auto !important;
}

.w-30 {
    width:30%!important
}

.w-35 {
    width:35%!important
}

.w-40 {
    width:40%!important
}

.w-45 {
    width:45%!important
}

.w-55 {
    width:55%!important
}

.w-60 {
    width:60%!important
}

.w-65 {
    width:65%!important
}

.w-70 {
    width:70%!important
}

.w-80 {
    width:80%!important
}

.w-85 {
    width:85%!important
}

.w-90 {
    width:90%!important
}

.w-95 {
    width:95%!important
}

// Responsive widths
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        @each $name, $value in $heights-and-widths {
            // Height
            .w#{$infix}-#{$name} {
                width: $value !important;
            }

            // Width
            .h#{$infix}-#{$name} {
                height: $value !important;
            }

            // Min width
            .min-w#{$infix}-#{$name} {
                min-width: $value !important;
            }

            // Min
            .min-h#{$infix}-#{$name} {
                min-height: $value !important;
            }

            .max-h#{$infix}-#{$name} {
                max-height: $value !important;
            }

            .max-w#{$infix}-#{$name} {
                max-width: $value !important;
            }
        }
    }
}
