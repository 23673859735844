//
// Base
//

// Body
body {
	background: $page-bg;
}

// Desktop Mode
@include media-breakpoint-up(lg) {
	// Wrapper
	.wrapper {
		// Fixed Header
		.header-fixed & {
			padding-top: get($header-config, desktop, fixed, height);
		}

		// Fixed Content Head
		.header-fixed.subheader-fixed.subheader-enabled & {
			padding-top: get($header-config, desktop, fixed, height) + get($subheader-config, fixed, height);
		}

		// Fixed Aside
		.aside-fixed & {
			padding-left: get($aside-config, base, width);
		}

		// Minimize Aside
		.aside-fixed.aside-minimize:not(.aside-minimize-hover) & {
			padding-left: get($aside-config, base, minimized-width);
		}
	}

	// Container
	.container,
	.container-fluid {
		padding: 0 get($page-padding, desktop);
	}
}

// Tablet & Mobile Modes
@include media-breakpoint-down(md) {
	// Wrapper
	.wrapper {
		// Fixed Header
		.header-mobile-fixed & {
			padding-top: get($header-config, tablet-and-mobile, fixed, height);
		}

		// Fixed Subheader
		.header-mobile-fixed.subheader-mobile-fixed.subheader-enabled & {
			padding-top: get($header-config, tablet-and-mobile, fixed, height) + get($subheader-config, fixed, height-mobile);
		}
	}

	// Container
	.container,
	.container-fluid {
		max-width: none;
		padding: 0 get($page-padding, tablet-and-mobile);
	}
}

// CALENDAR

.filtering .rbc-time-view, .filtering .rbc-month-view {
	opacity: 0.5;
}

.brand-dark .rbc-day-slot .rbc-events-container {
	margin-right: 0 !important;
}

.popover {
	margin: 10px;
	border: 1px solid #CCC;
}

.SingleDatePicker_picker {
    z-index: 9999 !important;
}

.selectable-slot {
	z-index: 1;
}

.selectable-slot:hover {
	background-color: #ccc;
	cursor: pointer;
}

.MuiButton-containedPrimary {
	color: white !important;
}
.rbc-header {
	padding: 0 !important;
	margin: auto !important;
	border: 0px !important;
	text-transform: uppercase;
	color: #FFF;
}

.rbc-header span {
	color: #3F4254 !important;
    font-family: Poppins, Helvetica, "sans-serif";
    font-weight: 400;
}

.brand-dark .rbc-time-view-resources .rbc-header, .brand-dark .rbc-time-view-resources .rbc-day-bg {
	padding: 0;
}

.rbc-time-content {
	>.rbc-today {
		border-left: 1px dashed #afafaf;
		border-right: 1px dashed #afafaf;
	}
}

.brand-light .rbc-today {
    background-color: #f8f8f8 !important;
}

.DateInput_input {
    font-weight: 100;
    font-size: 16px;
    color: #000000;
    background-color: #fff;
    width: 100%;
    padding: 11px 11px 9px;
    border: 1px solid #afafaf;
    border-radius: 5px;
    margin: 5px;
}

.DateInput_input__focused {
    font-weight: 100;
    font-size: 16px;
    color: #000000;
    background-color: #fff;
    width: 100%;
    padding: 11px 11px 9px;
    border: 1px solid #afafaf;
    border-radius: 5px;
    margin: 5px;
}

.customDatePickerWidth .DateInput {
   width: 100%;
}